
/**
 * 调入功能，只针对标段详情，暂不放再全局mixins中
 * @author suyonggang
 */
export default {
    data() {
        return {
            idList: [],
            isMultiple: false
        }
    },
    methods: {
        handleSelectionChange(val) {
            this.idList = val.map(item => {
                return item[this.identifier[0]];
            });
            console.log(this.idList)
        },
        onTransferredIn() {
            if (this.isMultiple) return
            let params = {
                idList: this.idList,
                type: this.identifier[1]
            }
            this.isMultiple = true
            this.$api_hw.districtInfo_bidSummaryDetailBringIn(params).then(res => {
                this.$notify({
                    title: '成功',
                    message: '数据调入成功',
                    type: 'success',
                    showClose: false
                });
                this.isMultiple = false
                this.$search(this.table);
            })
        }
    }
}